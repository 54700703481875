import styled from "styled-components";

const AuthorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ authorsNumber }) => (authorsNumber === 3 ? "3" : "2")},
    minmax(0, 1fr)
  );
  gap: 1vw;

  .team-category {
    color: white !important;
  }

  ${({ themeColor }) =>
    themeColor &&
    `
  .team-category {
    background: ${themeColor};
    border: none;
  }
  `}

  ${({ authorsNumber }) =>
    authorsNumber === 3 &&
    `
    .team-info-name {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 14vw;
    }

    .team-category {
      left: 0;
      margin-top: 0.4vw;
    }

    .team-photo {
      min-width: 6vw;
      width: 6vw;
    }
  `}

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 4vw;

    ${({ authorsNumber }) =>
      authorsNumber === 3 &&
      `
      .team-info-name {
        width: 100%;
      }

      .team-category {
        margin-top: 1.5vw;
      }

      .team-photo {
        min-width: 19.5vw;
        width: 19.5vw;
      }
    `}
  }
`;

export default AuthorsWrapper;
