// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/brutalism.jpg";
import ogImageVK from "assets/images/seo/vk/brutalism.jpg";
// data
import teamData from "data/team";
import localData from "data/pages/brutalism";
// hooks
import useBrutalismPortfolio from "hooks/portfolio/useBrutalismPortfolio";
import useBrutalismOfferPreview from "hooks/useBrutalismOfferPreview";
import useBrutalismQuery from "hooks/graphql/useBrutalismQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import MasterClassContent from "components/pages/course-page/master-class-content";

// 15.03.2024 Брутализм скрыли
const GraphicSystemBrutalismPage = () => {
  const prismicData = useBrutalismQuery();
  const portfolio = useBrutalismPortfolio();
  const offerImage = useBrutalismOfferPreview();

  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  const vadim = teamData.find(({ name }) => name === "Вадим Гранич");
  vadim.category = "Арт-директор";

  const sasha = teamData.find(({ name }) => name === "Саша Буяк");
  sasha.category = "Автор";

  const liza = teamData.find(({ name }) => name === "Елизавета Черникова");
  liza.category = "Исследователь";

  const authors = [
    {
      authorInfo: sasha,
      authorDescription: (
        <>
          <p>Графический дизайн и исследование</p>
          <p>Запись и ведение видеолекций</p>
        </>
      ),
    },
    {
      authorInfo: vadim,
      authorDescription: (
        <>
          <p>Арт-директор при разработке графсистемы</p>
        </>
      ),
    },
    {
      authorInfo: liza,
      authorDescription: (
        <>
          <p>Графический дизайн и исследование</p>
        </>
      ),
    },
  ];

  return (
    <Layout logo="brutalism">
      <div className="section-top-block" />
      <SEO
        title="Графсистемы Брутализм"
        description={
          seoData?.seodescription?.text ||
          "Отвергает шаблонный дизайн и декоративность"
        }
        keywords={["брутализм", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemBrutalism)}
        noIndex
      />
      <MasterClassContent
        disable
        offerWithPreview={offerImage}
        courseOffer={{ data }}
        courseExplanations={{ data: localData.BrutalismExplanations }}
        courseTitle={data.coursetitle?.text}
        coursePortfolio={{
          pinterestText: "Брутализм в Энциклопедии\nГрафдизайна Школы",
          pinterestLink: "https://www.pinterest.ru/vadim_granich/brutalism/",
          portfolio,
          graphicSystemName: "Брутализм",
        }}
        coursePriceRange={{
          formId: "ltForm4630442",
          priceRange: localData.priceRange,
          formPostUrl:
            "https://school.granich.design/pl/lite/block-public/process-html?id=1871736015",
        }}
        authors={authors}
      />
    </Layout>
  );
};

export default GraphicSystemBrutalismPage;
