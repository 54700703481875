/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useBrutalismOfferPreview = () => {
  const brutalismOfferImage = useStaticQuery(graphql`
    query brutalismOfferImg {
      brut: file(relativePath: { eq: "brutalism/brutalism.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const offerImage = brutalismOfferImage.brut.childImageSharp.fluid;

  return offerImage;
};

export default useBrutalismOfferPreview;
