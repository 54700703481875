import CoursePriceRange from "components/pages/course-page/price-range";
import React from "react";
import CourseExplanations from "components/pages/course-page/explanations";
import PaymentChoices from "components/pages/payment-choices";
import EducationQuestions from "../education-questions";
import MasterClassPortfolio from "../portfolio/MasterClassPortfolio";
import SchoolSystem from "../school-system";
import MasterClassTemplateOffer from "../offer/master-class";
import CourseAuthors from "../course-authors";

const MasterClassContent = ({
  courseTitle,
  courseOffer,
  courseExplanations = {},
  coursePriceRange = {},
  coursePortfolio = {},
  expressism,
  authors,
  fixedPortfolioHeight,
  offerWithPreview,
  disable,
}) => {
  const { data: offerData } = courseOffer;
  const { data } = courseExplanations;
  const { pinterestText, pinterestLink, portfolio, graphicSystemName } =
    coursePortfolio;
  const { formId, priceRange, formPostUrl } = coursePriceRange;
  return (
    <>
      <MasterClassTemplateOffer
        expressism={expressism}
        courseName={courseTitle}
        offerWithPreview={offerWithPreview}
        data={offerData}
      />
      <CourseExplanations twoRows data={data} />
      <SchoolSystem />
      <MasterClassPortfolio
        pinterestText={pinterestText}
        pinterestLink={pinterestLink}
        portfolio={portfolio}
        graphicSystemName={graphicSystemName}
        courseTitle={courseTitle}
        fixedHeight={fixedPortfolioHeight}
      />
      {authors && <CourseAuthors authors={authors} />}
      <div id="participation-section" />
      {!disable && (
        <CoursePriceRange
          formId={formId}
          priceRange={priceRange}
          courseTitle={courseTitle}
          formPostUrl={formPostUrl}
        />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
    </>
  );
};

export default MasterClassContent;
