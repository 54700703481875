import React from "react";
import { Container, Section, SectionTitle } from "components/style";
import TeamMember from "components/pages/index/team/teacher";
import AuthorsWrapper from "./style";

const CourseAuthors = ({ title, authors, themeColor }) => (
  <Section>
    <Container>
      <SectionTitle>{title ?? "Мастер-класс разрабатывали:"}</SectionTitle>
      <AuthorsWrapper themeColor={themeColor} authorsNumber={authors?.length}>
        {authors?.map((author, idx) => (
          <TeamMember
            key={idx}
            teamMember={author.authorInfo}
            customDescription={author.authorDescription}
            courseCard
            themeColor={themeColor}
          />
        ))}
      </AuthorsWrapper>
    </Container>
  </Section>
);

export default CourseAuthors;
