/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useBrutalismPortfolio = () => {
  const query = useStaticQuery(graphql`
    query brutalismPortfolio {
      poster1: file(relativePath: { eq: "brutalism/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "brutalism/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "brutalism/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "brutalism/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "brutalism/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "brutalism/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "brutalism/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "brutalism/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(relativePath: { eq: "brutalism/portfolio/09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(relativePath: { eq: "brutalism/portfolio/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster11: file(relativePath: { eq: "brutalism/portfolio/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(relativePath: { eq: "brutalism/portfolio/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster13: file(relativePath: { eq: "brutalism/portfolio/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster14: file(relativePath: { eq: "brutalism/portfolio/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster15: file(relativePath: { eq: "brutalism/portfolio/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Владислав Зинченко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535710ssd",
      src: portfolio[10].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579ss",
      src: portfolio[9].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535798",
      src: portfolio[8].childImageSharp.fluid,
      author: "Валерия Бухарова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535711",
      src: portfolio[11].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535712",
      src: portfolio[12].childImageSharp.fluid,
      author: "Аурика Сташкова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535714",
      src: portfolio[14].childImageSharp.fluid,
      author: "Владислав Зинченко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535713",
      src: portfolio[13].childImageSharp.fluid,
      author: "Игорь Сорока",
    },
  ];

  return data;
};

export default useBrutalismPortfolio;
