import React from "react";
import useSocialIconsQuery from "hooks/useSocialIconsQuery";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";

import * as SC from "./style";

const TeamMember = ({ teamMember, customDescription, courseCard }) => {
  const { vkIcon, pinterestIcon, instagramIcon, telegramIcon, behanceIcon } =
    useSocialIconsQuery();

  return (
    <SC.Wrapper courseCard={courseCard}>
      <SC.Header courseCard={courseCard}>
        <SC.Image
          className="team-photo"
          src={teamMember.avatar}
          courseCard={courseCard}
        />
        {!courseCard && (
          <SC.Socials>
            <SC.SocialIcons>
              {teamMember.telegram && (
                <SC.SocialLink
                  rel="noopener noreferrer"
                  href={teamMember.telegram}
                  target="_blank"
                >
                  <SC.SocialIcon fluid={telegramIcon} />
                </SC.SocialLink>
              )}
              {teamMember.vk && (
                <SC.SocialLink
                  rel="noopener noreferrer"
                  href={teamMember.vk}
                  target="_blank"
                >
                  <SC.SocialIcon fluid={vkIcon} />
                </SC.SocialLink>
              )}
              {teamMember.instagram && (
                <SC.SocialLink
                  rel="noopener noreferrer"
                  href={teamMember.instagram}
                  target="_blank"
                >
                  <SC.SocialIcon fluid={instagramIcon} />
                </SC.SocialLink>
              )}
              {teamMember.behance && (
                <SC.SocialLink
                  rel="noopener noreferrer"
                  href={teamMember.behance}
                  target="_blank"
                >
                  <SC.SocialIcon fluid={behanceIcon} />
                </SC.SocialLink>
              )}
              {teamMember.pinterest && (
                <SC.SocialLink
                  rel="noopener noreferrer"
                  href={teamMember.pinterest}
                  target="_blank"
                >
                  <SC.SocialIcon fluid={pinterestIcon} />
                </SC.SocialLink>
              )}
            </SC.SocialIcons>
            {teamMember.email && (
              <CopyMailToClipboardComponent mail={teamMember.email}>
                <SC.Email>{teamMember.email}</SC.Email>
              </CopyMailToClipboardComponent>
            )}
          </SC.Socials>
        )}
      </SC.Header>
      <SC.Info>
        <SC.InfoName className="team-info-name" courseCard={courseCard}>
          {teamMember.name}
          {teamMember.category && (
            <span className="team-category">{teamMember.category}</span>
          )}
        </SC.InfoName>
        <SC.Description courseCard={courseCard} className="our-team_link">
          {customDescription ??
            teamMember.aboutItems.map((item, idx) => <p key={idx}>{item}</p>)}
        </SC.Description>
      </SC.Info>
    </SC.Wrapper>
  );
};

export default TeamMember;
