const React = require("react");
/* eslint-disable */

module.exports = [
  {
    name: "Казимир Малевич",
    avatar: "/images/team/kazimir-malevich.jpg",
    category: "",
    email: null,
    instagram: null,
    pinterest: null,
    telegram: null,
    behance: null,
    vk: null,
    socialsOrder: [],
    aboutItems: [
      "Идейный вдохновитель",
      "Делал Supreme, когда это ещё не было мейнстримом",
      "Оставил свой QR-код в Третьяковской галерее",
    ],
  },
  {
    name: "Вадим Гранич",
    avatar: "/images/team/vadim.jpg",
    category: "Автор",
    email: "granich.vadim@yandex.ru",
    instagram: "https://www.instagram.com/pantrrrr/",
    pinterest: null,
    telegram: "https://t.me/vadim_granich",
    behance: null,
    vk: null,
    socialsOrder: ["Telegram", "Instagram"],
    aboutItems: [
      "Основатель Школы",
      "Автор курсов по графическому дизайну",
      <a
        href="https://t.me/granich_fire"
        rel="noopener noreferrer"
        target="_blank"
      >
        Дизайн-розжиг Гранича: всякое важное, что зацепило в дизайне
      </a>,
      <a
        href="https://t.me/granich_stop"
        rel="noopener noreferrer"
        target="_blank"
      >
        Личный блог Гранича: про осознания о жизни, вещах, теле, еде
      </a>,
    ],
  },
  {
    name: "Андрей Павлушин",
    avatar: "/images/team/andrey_2.jpg",
    category: "Автор",
    email: "p@vlushin.ru",
    instagram: "https://www.instagram.com/andrepavlushin/",
    pinterest: "https://in.pinterest.com/andrepavlushin/",
    telegram: "https://t.me/andrepavlushin",
    behance: "https://www.behance.net/pavlushin",
    vk: "https://vk.com/andrepavlushin",
    socialsOrder: ["Telegram", "VK", "Instagram", "Behance", "Pinterest"],
    aboutItems: [
      "Арт-директор в команде Granich",
      "Соавтор курса Осознанный Медиадизайн",
      <>
        Ведёт{" "}
        <a
          href="https://pavlushin.design/workshop/artreview/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Артдирекшен
        </a>
      </>,
      <>
        Ведёт блог для дизайнеров{" "}
        <a
          href="https://t.me/andreypavlushin"
          rel="noopener noreferrer"
          target="_blank"
        >
          Артель Павлушина
        </a>
      </>,
      <>
        Автор{" "}
        <a
          href="https://fonts.pavlushin.design/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Шрифтотеки
        </a>
      </>,
    ],
  },
  {
    name: "Елизавета Черникова",
    avatar: "/images/team/liza_1.jpg",
    category: "Автор",
    email: null,
    instagram: null,
    pinterest: "https://www.pinterest.ru/lissabilberry/",
    telegram: "https://t.me/lissabilberry",
    behance: "https://www.behance.net/Lissa-Chernikova",
    vk: "https://vk.com/lissabilberry",
    socialsOrder: ["Telegram", "VK", "Pinterest", "Behance"],
    aboutItems: [
      "Графический исследователь",
      <>
        Соавтор курса{" "}
        <a
          href="https://granich.design/graphic-metaphors/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Графических Метафор
        </a>
      </>,
      <>
        Ведёт блог по графическим метафорам{" "}
        <a
          href="https://t.me/graflab"
          rel="noopener noreferrer"
          target="_blank"
        >
          Графлаб
        </a>
      </>,
      "Автор мастер-классов по графдизайну",
    ],
  },
  {
    name: "Юлия Есинкина",
    avatar: "/images/team/yulia-yesinkina.png",
    category: "Автор",
    email: "yesinkina@mail.ru",
    instagram: "https://instagram.com/esinkina_dsgn",
    pinterest: null,
    telegram: "https://www.t.me/yesinkina",
    behance: "https://www.behance.net/yesinkina",
    vk: null,
    socialsOrder: ["Telegram", "Instagram", "Behance"],
    aboutItems: [
      "Соавтор курса Графдизайн Руками",
      "Ведёт Артдирекшн",
      "Выпускница НИУ ВШЭ «Коммуникационный дизайн»",
      "Старший дизайнер маркетинговых коммуникаций Мвидео",
    ],
  },
  {
    name: "София Медведева",
    avatar: "/images/team/sofia.jpg",
    category: "Координатор",
    email: "sofoochaa@gmail.com",
    instagram: null,
    pinterest: null,
    telegram: "https://t.me/Sofoochaa",
    behance: null,
    vk: "https://vk.com/sofoochaa",
    socialsOrder: ["Telegram", "VK"],
    aboutItems: [
      "Коммьюнити-менеджер",
      "Координация учебных потоков",
      "Решение вопросов с учениками и юрлицами",
    ],
  },
  {
    name: "Владислав Садовский",
    avatar: "/images/team/vlad_sadovsky.jpg",
    category: "Куратор",
    email: "zeno.citi@gmail.com",
    instagram: "https://www.instagram.com/drabaslav/",
    pinterest: "https://www.pinterest.ca/drabaslav",
    telegram: "https://t.me/pansadowski",
    behance: "https://www.behance.net/513c6f3f",
    vk: null,
    socialsOrder: ["Telegram", "Instagram", "Pinterest", "Behance"],
    aboutItems: ["Куратор Фундамента Графдизайна"],
  },
  {
    name: "Алиса Брюква",
    avatar: "/images/team/alice.jpg",
    category: "Автор",
    email: "rutubagabrukwa@gmail.com",
    instagram: "https://www.instagram.com/rutubaga/",
    pinterest: "https://pin.it/4BTZiXr",
    telegram: "https://t.me/brukwa",
    behance: "https://www.behance.net/alisabrukwa",
    vk: "https://vk.com/idrutubaga",
    socialsOrder: ["Telegram", "Vk", "Behance"],
    aboutItems: ["Соавтор мастер-класса Granich Midjourney"],
  },
  {
    name: "Камилла Хусаинова",
    avatar: "/images/team/camilla.jpg",
    category: "Куратор",
    email: "khuskam0163@gmail.com",
    instagram: "https://www.instagram.com/kam.saf",
    pinterest: null,
    telegram: "https://t.me/kam_saf",
    behance: "https://www.behance.net/kamillakhusainova",
    vk: "https://vk.com/kam1008",
    socialsOrder: ["Telegram", "Behance", "Instagram", "VK"],
    aboutItems: ["Куратор Айдентики через Метафоры"],
  },
  {
    name: "Саша Буяк",
    avatar: "/images/team/sasha-buyak_2.jpg",
    category: "Соавтор",
    email: "Bubbuyak@yandex.ru",
    instagram: null,
    pinterest: null,
    telegram: "https://t.me/Sasha_Buyak",
    behance: "https://www.behance.net/7719517c",
    vk: null,
    socialsOrder: ["Telegram", "Behance"],
    aboutItems: ["Графический дизайнер", "Исследователь графсистем"],
  },
];
