/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "4958820",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "4958828",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "4958830",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "4958832",
  },
];
export default {
  BrutalismExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/brutalism/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Брутализм?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Графический Брутализм берёт свое начало в архитектурном стиле, который возник в послевоенный период. Архитектурный и графический Брутализм борются с шаблонным дизайном и декоративностью, стремясь выразить необработанность, сырость и грубость материалов, а также честность и функциональность. Брутализм продолжает вызывать дискуссии и споры среди людей. Некоторые находят в Брутализме красоту и эстетическое удовольствие, видя в нём смелое и нестандартное решение, которое отличается от заезженных шаблонов. Когда-то в Школе уже был Брутализм, но сейчас мы полностью перезапускаем эту Графсистему. Там набралось уже 144 слайда, можно считать это глобальным обновлением.",
    },
  ],
  priceRange,
};
